import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { AuthContext } from "../../App";
import { getBoards } from "../../services/ApiClass";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey("YOUR_LICENSE_KEY");

const Boards = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [boards, setBoards] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [sortModel, setSortModel] = useState([]);
  const [idDecodificato, setIdDecodificato] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await getBoards(pageState.pageSize, {
        page: pageState.page,
        filterModel,
        sortModel,
      });
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: response.output.data,
        total: response.output.total,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize, filterModel, sortModel]);

  useEffect(() => {
    if (!state.tokenJwt) {
      navigate("/login");
    }
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.id?.toString(16)} → ${params.row.id}`,
    },
    {
      field: "level",
      headerName: "Isola",
      flex: 0.3,
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.level.name || ""}`,
    },
    {
      field: "legend",
      headerName: "Legend",
      flex: 0.3,
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.legend.username || ""}`,
    },
    {
      field: "guide",
      headerName: "Guide",
      flex: 0.3,
      sortable: false,
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.leftBoard.guide?.user?.username || ""} ${
          params.row.rightBoard.guide?.user?.username || ""
        }`,
      renderCell: ({ row: { leftBoard, rightBoard } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            display="block"
            justifyContent="left"
            borderRadius="4px"
          >
            <Typography sx={{ mb: "5px" }}>
              {leftBoard.guide?.user?.username}
            </Typography>
            <Typography sx={{ mb: "5px" }}>
              {rightBoard.guide?.user?.username}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "builder",
      headerName: "Builder",
      flex: 0.6,
      sortable: false,
      headerAlign: "center",
      align: "center",
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.leftBoard.builder1?.user?.username || ""} ${
          params.row.leftBoard.builder2?.user?.username || ""
        } ${params.row.rightBoard.builder1?.user?.username || ""} ${
          params.row.rightBoard.builder2?.user?.username || ""
        }`,
      renderCell: ({ row: { leftBoard, rightBoard } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            display="block"
            justifyContent="left"
            borderRadius="4px"
          >
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <Typography sx={{ gridColumn: "span 2" }}>
                {leftBoard.builder1?.user?.username}
              </Typography>
              <Typography sx={{ gridColumn: "span 2" }}>
                {leftBoard.builder2?.user?.username}
              </Typography>
              <Typography sx={{ gridColumn: "span 2" }}>
                {rightBoard.builder1?.user?.username}
              </Typography>
              <Typography sx={{ gridColumn: "span 2" }}>
                {rightBoard.builder2?.user?.username}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "gifter",
      headerName: "Gifter",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.leftBoard.gifter1?.user?.username || ""} ${
          params.row.leftBoard.gifter2?.user?.username || ""
        } ${params.row.leftBoard.gifter3?.user?.username || ""} ${
          params.row.leftBoard.gifter4?.user?.username || ""
        } ${params.row.rightBoard.gifter1?.user?.username || ""} ${
          params.row.rightBoard.gifter2?.user?.username || ""
        } ${params.row.rightBoard.gifter3?.user?.username || ""} ${
          params.row.rightBoard.gifter4?.user?.username || ""
        }`,
      renderCell: ({ row: { leftBoard, rightBoard } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            display="block"
            justifyContent="left"
            borderRadius="4px"
          >
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(8, minmax(0, 1fr))"
            >
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  leftBoard.gifter1 &&
                  !leftBoard.gifter1.confirmed &&
                  colors.redAccent[400]
                }
              >
                {leftBoard.gifter1?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  leftBoard.gifter2 &&
                  !leftBoard.gifter2.confirmed &&
                  colors.redAccent[400]
                }
              >
                {leftBoard.gifter2?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  leftBoard.gifter3 &&
                  !leftBoard.gifter3.confirmed &&
                  colors.redAccent[400]
                }
              >
                {leftBoard.gifter3?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  leftBoard.gifter4 &&
                  !leftBoard.gifter4.confirmed &&
                  colors.redAccent[400]
                }
              >
                {leftBoard.gifter4?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  rightBoard.gifter1 &&
                  !rightBoard.gifter1.confirmed &&
                  colors.redAccent[400]
                }
              >
                {rightBoard.gifter1?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  rightBoard.gifter2 &&
                  !rightBoard.gifter2.confirmed &&
                  colors.redAccent[400]
                }
              >
                {rightBoard.gifter2?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  rightBoard.gifter3 &&
                  !rightBoard.gifter3.confirmed &&
                  colors.redAccent[400]
                }
              >
                {rightBoard.gifter3?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                color={
                  rightBoard.gifter4 &&
                  !rightBoard.gifter4.confirmed &&
                  colors.redAccent[400]
                }
              >
                {rightBoard.gifter4?.user?.username ?? "-"}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "activeTime",
      headerName: "Durata",
      type: "number",
      sortable: false,
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.activeTime / 60,
      renderCell: ({ row: { activeTime } }) => secondsToDhms(activeTime),
    },
    {
      field: "closed",
      headerName: "Chiuso",
      type: "boolean",
      flex: 0.1,
      renderCell: (params) => {
        return params.value ? (
          <CheckIcon
            style={{
              color: theme.palette.success.light,
            }}
          />
        ) : (
          <CloseIcon
            style={{
              color: colors.grey[500],
            }}
          />
        );
      },
    },
  ];

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay /* + sDisplay*/;
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    window.open(`/boards/${params?.row?.id}`, "_blank").focus();
    // navigate(`/boards/${params?.row?.id}`);
  };

  return (
    <Box m="20px" mt="0px">
      <Header title="Boards" subtitle="List of Boards" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(6, minmax(0, 1fr))"
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Id codificato"
          onChange={(value) =>
            setIdDecodificato(
              value.target.value ? parseInt(value.target.value, 16) : ""
            )
          }
          sx={{ gridColumn: "span 3" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Id"
          value={idDecodificato}
          disabled
          sx={{ gridColumn: "span 3" }}
        />
      </Box>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // autoHeight
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onPageChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage + 1 }));
          }}
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          columns={columns}
          onRowClick={handleRowClick}
          onSortModelChange={setSortModel}
          onFilterModelChange={setFilterModel}
          // slots={{ toolbar: GridToolbar }}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Boards;
