import { useState, createContext, useReducer } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Login from "./scenes/login";
import User from "./scenes/user";
import UserProfile from "./scenes/user/profile";
import Isola from "./scenes/isola";
import IsolaDetail from "./scenes/isola/profile";
import Boards from "./scenes/boards";
import BoardDetail from "./scenes/boards/detail";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { initialState, reducer } from "./store/reducer";

export const AuthContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {state.tokenJwt && <Sidebar isSidebar={isSidebar} />}
            <main className="content">
              {state.tokenJwt && <Topbar setIsSidebar={setIsSidebar} />}
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/user" element={<User />} />
                {/* <Route path="/user" element={<UserProfile />} /> */}
                <Route path="/user/:id" element={<UserProfile />} />
                <Route path="/boards" element={<Boards />} />
                <Route path="/boards/:id" element={<BoardDetail />} />
                <Route path="/isola" element={<Isola />} />
                <Route path="/isola/:id" element={<IsolaDetail />} />
                <Route path="*" element={<p>Path not resolved</p>} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
