export const initialState = {
  tokenJwt: JSON.parse(localStorage.getItem("tokenJwt")) || null,
  user: JSON.parse(localStorage.getItem("user")) || null,
  invitationLink: JSON.parse(localStorage.getItem("invitationLink")) || null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("tokenJwt", JSON.stringify(action.payload.tokenJwt));
      return {
        ...state,
        tokenJwt: action.payload.tokenJwt,
        user: action.payload.user ?? {},
      };
    }
    case "LOGOUT": {
      localStorage.clear();
      return {
        ...state,
        tokenJwt: null,
        user: null,
      };
    }
    case "USER": {
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case "INVITATIONLINK": {
      localStorage.setItem(
        "invitationLink",
        JSON.stringify(action.payload.invitationLink)
      );
      return {
        ...state,
        invitationLink: action.payload.invitationLink,
      };
    }
    default:
      return state;
  }
};
