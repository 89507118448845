import axios from "axios";

export const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers.authorization = `Bearer ${JSON.parse(
        localStorage.getItem("tokenJwt")
      )}`;
    }
    return config;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };
    return customError;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (
      error.response.status === 401 &&
      !error.response.config?.url?.includes("login") &&
      !error.response.config?.url?.includes("register")
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
    const resp = error.response.output ?? error.response.data;
    return { error: resp };
  }
);

export const userLists = async (perPage = 0, params = {}) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/user/index${
      perPage > 0 ? "/" + perPage : ""
    }`,
    { params }
  );
};

export const getUserById = async (id) => {
  return await axiosInstance.get(`${process.env.REACT_APP_API_URL}/user/${id}`);
};

export const postUser = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/user`,
    data
  );
};

export const patchUser = async (data, id) => {
  return await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/user/${id}`,
    data
  );
};

export const loginApi = async (username, password) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/auth/login`,
    {
      username,
      password,
    }
  );
};

export const registerApi = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/auth/register`,
    { ...data }
  );
};

export const logoutApi = async () => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/auth/logout`,
    {}
  );
};

export const signOut = async (token) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/logout`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const userInfoApi = async (token) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/auth/me`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);
};

export const confirmEmail = async (user_id) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/user/confirm-email`,
    { user_id }
  );
};

export const requestConfirmationEmail = async (data) => {
  return await axios
    .post(
      `${process.env.REACT_APP_API_URL}/user/request-confirmation-email`,
      data
    )
    .then((res) => res.data)
    .catch((err) => err?.response?.data);
};

export const forgotPassword = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
    data
  );
};

export const restoreAccount = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/user/restore-account`,
    data
  );
};

export const getBoards = async (perPage = 0, params = {}) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/board/master/index${
      perPage > 0 ? "/" + perPage : ""
    }?show=true`,
    { params }
  );
};

export const getBoardById = async (id) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/board/master/show/${id}`
  );
};

export const boardLevel = async () => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/board/level/index`
  );
};

export const getBoardLevel = async (id) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/board/level/${id}`
  );
};

export const updateBoardLevel = async (id, data) => {
  return await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/board/level/${id}`,
    data
  );
};

export const showBoard = async (id) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/board/show/${id}`
  );
};

export const nextBoard = async (boardLevel) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/board/nextBoard`,
    { boardLevel }
  );
};

export const joinBoard = async (id) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/board/joinBoard`,
    { board_id: id }
  );
};

export const addToBoard = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/board/joinBoard`,
    { ...data }
  );
};

export const updateUser = async (id, data) => {
  return await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/user/${id}`,
    { ...data }
  );
};

export const changePassword = async (id, data) => {
  return await axiosInstance.patch(
    `${process.env.REACT_APP_API_URL}/user/changePassword/${id}`,
    { ...data }
  );
};

export const inviteLink = async () => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}/user/inviteLink`
  );
};

export const confirmGifter = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/board/confirmGifter`,
    { ...data }
  );
};

export const removeGifter = async (data) => {
  return await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}/board/removeGifter`,
    { ...data }
  );
};

const handleMessage = async (message, method = "error", callback) => {
  if (message) {
    let msg = "";
    if (typeof message == "string") {
      msg = message;
    } else {
      for (let key in message) {
        for (let error of message[key]) {
          msg += error + "<br>";
        }
      }
    }
    callback(msg, method);
  }
};

export const handleRequest = async (response, callback) => {
  if (response && response.body) {
    handleMessage(
      response.body.extra?.message,
      response.body.return ? "info" : "error",
      callback
    );
  } else if (response.error) {
    if (response.error.message && response.error.hasOwnProperty("return")) {
      handleMessage(
        response.error.message,
        response.error.return ? "info" : "error",
        callback
      );
    } else {
      handleMessage(response.error.extra?.message, "error", callback);
    }
  } else if (response.output) {
    handleMessage(
      response.extra?.message,
      response.return ? "info" : "error",
      callback
    );
  }
};
