import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
//import Alert from '@mui/material/Alert';
import MuiAlert from "@mui/material/Alert";

const SnackbarContext = createContext();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  const [isConnected, setIsConnected] = useState("");

  const openSnackbar = (message, type = "success") => {
    setMessage(message);
    setType(type);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 4000); // Close the Snackbar after 3 seconds (adjust as needed)
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ openSnackbar, closeSnackbar, isConnected, setIsConnected }}
    >
      {children}
      {open && (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={closeSnackbar}
        >
          <Alert
            vertical="bottom"
            horizontal="center"
            onClose={closeSnackbar}
            severity={type}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
}
