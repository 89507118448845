import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  circularProgressClasses,
  colors,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { images } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import Animate from "../../components/common/Animate";
import { loginApi, userInfoApi } from "../../services/ApiClass";
import { AuthContext } from "../../App";
import { useSnackbar } from "../../components/SnackbarContext"; // Import useSnackbar

const Login = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const { openSnackbar } = useSnackbar();

  const [onRequest, setOnRequest] = useState(false);
  const [loginProgress, setLoginProgress] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (state.tokenJwt) {
      navigate("/dashboard");
    }
  }, []);

  const onSignin = async (e) => {
    e.preventDefault();
    setOnRequest(true);

    const interval = setInterval(() => {
      setLoginProgress((prev) => prev + 100 / 40);
    }, 50);

    const response = await loginApi(username, password);
    if (response && response.return) {
      if (
        ["admin", "assistente", "gestionale"].includes(response.output.role)
      ) {
        const info = await userInfoApi(response.output.access_token);
        if (info && info.return) {
          setTimeout(() => {
            dispatch({
              type: "LOGIN",
              payload: {
                tokenJwt: response.output.access_token,
                user: info.output,
              },
            });
            navigate("/dashboard");
          }, 3300);
        }
        setTimeout(() => {
          clearInterval(interval);
        }, 2000);
        setTimeout(() => {
          setIsLoggedIn(true);
        }, 2100);
      } else {
        openSnackbar("Unauthorized", "error");
        setOnRequest(false);
        setLoginProgress(0);
        clearInterval(interval);
      }
    } else {
      setOnRequest(false);
      setLoginProgress(0);
    }
  };

  return (
    <Box
      position="relative"
      height="100vh"
      sx={{
        "::-webkit-scrollbar": { display: "none" },
        ".css-1dpvz57": { backgroundColor: "#141b2d" },
      }}
    >
      {/* background box */}
      <Box
        sx={{
          position: "absolute",
          right: 0,
          height: "100%",
          width: "70%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${images.loginBg})`,
        }}
      />
      {/* background box */}

      {/* Login form */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          height: "100%",
          width: isLoggedIn
            ? "100%"
            : { xl: "30%", lg: "40%", md: "50%", xs: "100%" },
          transition: "all 1s ease-in-out",
          bgcolor: colors.common.white,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            opacity: isLoggedIn ? 0 : 1,
            transition: "all 0.3s ease-in-out",
            height: "100%",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          {/* logo */}
          <Box sx={{ textAlign: "center", p: 5 }}>
            <Animate type="fade" delay={0.5}>
              <img src={images.logo} alt="logo" height={60}></img>
            </Animate>
          </Box>
          {/* logo */}

          {/* form */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Animate type="fade" sx={{ maxWidth: 400, width: "100%" }}>
              <Box
                component="form"
                maxWidth={400}
                width="100%"
                onSubmit={onSignin}
              >
                <Stack spacing={3}>
                  <TextField
                    label="username"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // "& fieldset": {
                        //   borderColor: "pink",
                        // },
                        // "&:hover fieldset": {
                        //   borderColor: "yellow",
                        // },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                  <TextField
                    label="password"
                    type="password"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="success"
                  >
                    sign in
                  </Button>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Remember me"
                      />
                    </FormGroup>
                    <Typography color="error" fontWeight="bold">
                      <Link to="#">Forgot password?</Link>
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Animate>
          </Box>
          {/* form */}

          {/* footer */}
          <Box sx={{ textAlign: "center", p: 5, zIndex: 2 }}>
            <Animate type="fade" delay={1}>
              <Typography
                display="inline"
                fontWeight="bold"
                sx={{ "& > a": { color: colors.red[900], ml: "5px" } }}
              >
                Don't have an account -<Link to="#">Register now</Link>
              </Typography>
            </Animate>
          </Box>
          {/* footer */}

          {/* loading box */}
          {onRequest && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                bgcolor: colors.common.white,
                zIndex: 1000,
              }}
            >
              <Box position="relative">
                <CircularProgress
                  variant="determinate"
                  sx={{ color: colors.grey[200] }}
                  size={100}
                  value={100}
                />
                <CircularProgress
                  variant="determinate"
                  value={loginProgress}
                  size={100}
                  sx={{
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                    position: "absolute",
                    left: 0,
                    color: colors.green[600],
                  }}
                />
              </Box>
            </Stack>
          )}
          {/* loading box */}
        </Box>
      </Box>
      {/* Login form */}
    </Box>
  );
};

export default Login;
