import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  Modal,
  FormControl,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../App";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {
  userLists,
  getBoards,
  getBoardById,
  addToBoard,
  confirmGifter,
  removeGifter,
  handleRequest,
} from "../../../services/ApiClass";
import { useSnackbar } from "../../../components/SnackbarContext"; // Import useSnackbar
import UserBox from "./user-box";

const BoardDetail = (props) => {
  let { id } = useParams();
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [board, setBoard] = useState([]);
  const [users, setUsers] = useState([]);

  const isNonMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    if (!state.tokenJwt) {
      navigate("/login");
    }
    // userLists().then((response) => {
    //   setUsers(response.output);
    // });
  }, []);

  useEffect(() => {
    setLoading(true);
    getBoardById(id).then((response) => {
      setBoard(response.output);
      // console.log(response);
      setLoading(false);
    });
  }, [id]);

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay /* + sDisplay*/;
  };

  const onAddGifter = async (user, position, gifter) => {
    setLoading(true);
    const response = await addToBoard({
      board_id: board[position].id,
      user_id: user.id,
    });
    handleRequest(response, openSnackbar);
    if (response.return) {
      setBoard({
        ...board,
        [position]: {
          ...board[position],
          [gifter]: {
            ...board[position][gifter],
            confirmed: 0,
            user: { ...user },
          },
        },
      });
    }
    setLoading(false);
  };

  const onConfirmGifter = async (position, gifter) => {
    setLoading(true);
    const response = await confirmGifter({
      board_id: board[position].id,
      user_id: board[position][gifter].user_id,
      id: board[position][gifter].id,
    });
    handleRequest(response, openSnackbar);
    if (response.return) {
      setBoard({
        ...board,
        [position]: {
          ...board[position],
          [gifter]: {
            ...board[position][gifter],
            confirmed: 1,
          },
        },
      });
    }
    setLoading(false);
  };

  const onRemoveGifter = async (position, gifter) => {
    setLoading(true);
    const response = await removeGifter({
      board_id: board[position].id,
      user_id: board[position][gifter].user_id,
      forced: true,
    });
    handleRequest(response, openSnackbar);
    if (response.return) {
      setBoard({
        ...board,
        [position]: {
          ...board[position],
          [gifter]: undefined,
        },
      });
    }
    setLoading(false);
  };

  return (
    <Box m="20px">
      <Header title="Board" subtitle="Manage Board" />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography sx={{ textAlign: "center" }} variant="h3">
        Board {id} creato il {board.created_at?.replace("T", " ").split(".")[0]}
      </Typography>
      <Box
        display="grid"
        gap="20px"
        gridTemplateColumns={`repeat(7, minmax(0, 1fr${
          !isNonMobile ? ", 1fr" : ""
        }))`}
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          height: "75vh",
        }}
      >
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(4, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Gifter 1"
            board={board.leftBoard?.gifter1}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "leftBoard", "gifter1")
            }
            onConfirmGifter={() => onConfirmGifter("leftBoard", "gifter1")}
            onRemoveGifter={() => onRemoveGifter("leftBoard", "gifter1")}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Gifter 2"
            board={board.leftBoard?.gifter2}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "leftBoard", "gifter2")
            }
            onConfirmGifter={() => onConfirmGifter("leftBoard", "gifter2")}
            onRemoveGifter={() => onRemoveGifter("leftBoard", "gifter2")}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Gifter 3"
            board={board.leftBoard?.gifter3}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "leftBoard", "gifter3")
            }
            onConfirmGifter={() => onConfirmGifter("leftBoard", "gifter3")}
            onRemoveGifter={() => onRemoveGifter("leftBoard", "gifter3")}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Gifter 4"
            board={board.leftBoard?.gifter4}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "leftBoard", "gifter4")
            }
            onConfirmGifter={() => onConfirmGifter("leftBoard", "gifter4")}
            onRemoveGifter={() => onRemoveGifter("leftBoard", "gifter4")}
          />
        </Box>
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(4, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box sx={{ gridColumn: "span 1" }} />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Builder 1"
            board={board.leftBoard?.builder1}
            users={users}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Builder 2"
            board={board.leftBoard?.builder2}
            users={users}
          />
          <Box sx={{ gridColumn: "span 1" }} />
        </Box>
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(3, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box sx={{ gridColumn: "span 1" }} />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Left Guide"
            board={board.leftBoard?.guide}
            users={users}
          />
          <Box sx={{ gridColumn: "span 1" }} />
        </Box>
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(3, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box sx={{ gridColumn: "span 1" }} />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Legenda"
            legend={board.legend}
          />
          <Box sx={{ gridColumn: "span 1" }} />
        </Box>
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(3, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box sx={{ gridColumn: "span 1" }} />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Guide"
            board={board.rightBoard?.guide}
            users={users}
          />
          <Box sx={{ gridColumn: "span 1" }} />
        </Box>
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(4, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Box sx={{ gridColumn: "span 1" }} />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Builder 1"
            board={board.rightBoard?.builder1}
            users={users}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Builder 2"
            board={board.rightBoard?.builder2}
            users={users}
          />
          <Box sx={{ gridColumn: "span 1" }} />
        </Box>
        <Box
          display="grid"
          gap="5%"
          gridTemplateColumns={`repeat(4, minmax(0, 1fr${
            isNonMobile ? ", 1fr" : ""
          }))`}
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Gifter 1"
            board={board.rightBoard?.gifter1}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "rightBoard", "gifter1")
            }
            onConfirmGifter={() => onConfirmGifter("rightBoard", "gifter1")}
            onRemoveGifter={() => onRemoveGifter("rightBoard", "gifter1")}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Gifter 2"
            board={board.rightBoard?.gifter2}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "rightBoard", "gifter2")
            }
            onConfirmGifter={() => onConfirmGifter("rightBoard", "gifter2")}
            onRemoveGifter={() => onRemoveGifter("rightBoard", "gifter2")}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Gifter 3"
            board={board.rightBoard?.gifter3}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "rightBoard", "gifter3")
            }
            onConfirmGifter={() => onConfirmGifter("rightBoard", "gifter3")}
            onRemoveGifter={() => onRemoveGifter("rightBoard", "gifter3")}
          />
          <UserBox
            sx={{ gridColumn: "span 1" }}
            title="Right Gifter 4"
            board={board.rightBoard?.gifter4}
            users={users}
            onAddGifter={(username) =>
              onAddGifter(username, "rightBoard", "gifter4")
            }
            onConfirmGifter={() => onConfirmGifter("rightBoard", "gifter4")}
            onRemoveGifter={() => onRemoveGifter("rightBoard", "gifter4")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BoardDetail;
