import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  getGridStringOperators,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { AuthContext } from "../../App";
import { userLists } from "../../services/ApiClass";

const User = () => {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [gridApi, setGridApi] = React.useState(null);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [sortModel, setSortModel] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await userLists(pageState.pageSize, {
        page: pageState.page,
        filterModel,
        sortModel,
      });
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: response.output.data,
        total: response.output.total,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize, filterModel, sortModel]);

  useEffect(() => {
    if (!state.tokenJwt) {
      navigate("/login");
    }
  }, []);

  const columns = [
    { field: "id", headerName: "ID", type: "number" },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "email_verified_at",
      headerName: "Email Confermato",
      type: "date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.email_verified_at
          ? new Date(params.row.email_verified_at).toLocaleString()
          : "",
    },
    {
      field: "role",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === "admin"
                ? colors.greenAccent[600]
                : role === "manager"
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {role === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {role === "eternal" && <SecurityOutlinedIcon />}
            {(role === "user" || role === "founder") && (
              <LockOpenOutlinedIcon />
            )}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    window.open(`/user/${params?.row?.id}`, "_blank").focus();
    // navigate(`/user/${params?.row?.id}`);
  };

  const handleRemoveOperators = (columnName) => {
    console.log(columnName);
    if (gridApi) {
      const updatedFilters = gridApi.getFilterModel();
      if (updatedFilters[columnName]) {
        const allowedOperators = ["isEmpty", "isNotEmpty"]; // Operatori consentiti
        updatedFilters[columnName].items = updatedFilters[
          columnName
        ].items.filter((item) => allowedOperators.includes(item.operator));
        gridApi.setFilterModel(updatedFilters);
      }
    }
  };

  const CustomFilterOperators = () => {
    return (
      <div>
        <button onClick={() => handleRemoveOperators("email_verified_at")}>
          Rimuovi Operatori First Name
        </button>
        {/* Aggiungi altri controlli per altre colonne se necessario */}
      </div>
    );
  };

  return (
    <Box m="20px">
      <Header title="Users" subtitle="Managing Users" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // autoHeight
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onPageChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage + 1 }));
          }}
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          columns={columns}
          onRowClick={handleRowClick}
          onSortModelChange={setSortModel}
          onFilterModelChange={setFilterModel}
          // slots={{ toolbar: GridToolbar }}
          components={{
            Toolbar: () => (
              <GridToolbar>
                <CustomFilterOperators />
              </GridToolbar>
            ),
          }}
          apiRef={(api) => setGridApi(api)}
        />
      </Box>
    </Box>
  );
};

export default User;
