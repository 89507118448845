import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { DataGrid, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../App";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {
  userLists,
  getUserById,
  postUser,
  patchUser,
  getBoards,
  requestConfirmationEmail,
  forgotPassword,
  restoreAccount,
  confirmEmail,
  handleRequest,
} from "../../../services/ApiClass";
import { useSnackbar } from "../../../components/SnackbarContext"; // Import useSnackbar

const UserProfile = (props) => {
  let { id } = useParams();
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(initialValues);
  const [users, setUsers] = useState([]);
  const [tab, setTab] = React.useState(0);

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [sortModel, setSortModel] = useState([]);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (!state.tokenJwt) {
      navigate("/login");
    }
    // userLists().then((response) => {
    //   setUsers(response.output);
    // });
  }, []);

  useEffect(() => {
    setLoading(true);
    getUserById(id).then((response) => {
      handleRequest(response, openSnackbar);
      setUserData(response.output);
      if (!users.length) {
        const data = [];
        if (response.output.inviter) {
          data.push(response.output.inviter);
        }
        if (response.output.parent) {
          data.push(response.output.parent);
        }
        setUsers([...data]);
      }
      setLoading(false);
    });
  }, [id]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    let response = null;
    if (id && values.id) {
      response = await patchUser(values, id);
    } else {
      response = await postUser(values);
    }
    handleRequest(response, openSnackbar);
    setLoading(false);
  };

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    // if (newValue === 1 && !boards.length) {
    //   setLoading(true);
    //   const response = await getBoards({ user_id: id });
    //   setBoards(response.output);
    //   setLoading(false);
    // }
    setTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await getBoards(pageState.pageSize, {
        page: pageState.page,
        user_id: id,
        filterModel,
        sortModel,
      });
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: response.output.data,
        total: response.output.total,
      }));
    };
    fetchData();
  }, [pageState.page, pageState.pageSize, filterModel, sortModel]);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      flex: 0.15,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.id?.toString(16)} → ${params.row.id}`,
    },
    {
      field: "level",
      headerName: "Isola",
      flex: 0.3,
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.level.name || ""}`,
    },
    {
      field: "legend",
      headerName: "Legend",
      flex: 0.3,
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.legend.username || ""}`,
      renderCell: ({ row: { legend } }) => {
        return (
          <Typography
            backgroundColor={legend.id === id && colors.greenAccent[500]}
            color={legend.id === id && colors.grey[900]}
          >
            {legend.username}
          </Typography>
        );
      },
    },
    {
      field: "guide",
      headerName: "Guide",
      flex: 0.3,
      sortable: false,
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.leftBoard.guide?.user?.username || ""} ${
          params.row.rightBoard.guide?.user?.username || ""
        }`,
      renderCell: ({ row: { leftBoard, rightBoard } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            display="block"
            justifyContent="left"
            borderRadius="4px"
          >
            <Typography
              sx={{ mb: "5px" }}
              backgroundColor={
                leftBoard.guide?.user?.id === id && colors.greenAccent[500]
              }
              color={leftBoard.guide?.user?.id === id && colors.grey[900]}
            >
              {leftBoard.guide?.user?.username}
            </Typography>
            <Typography
              sx={{ mb: "5px" }}
              backgroundColor={
                rightBoard.guide?.user?.id === id && colors.greenAccent[500]
              }
              color={rightBoard.guide?.user?.id === id && colors.grey[900]}
            >
              {rightBoard.guide?.user?.username}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "builder",
      headerName: "Builder",
      flex: 0.6,
      sortable: false,
      headerAlign: "center",
      align: "center",
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.leftBoard.builder1?.user?.username || ""} ${
          params.row.leftBoard.builder2?.user?.username || ""
        } ${params.row.rightBoard.builder1?.user?.username || ""} ${
          params.row.rightBoard.builder2?.user?.username || ""
        }`,
      renderCell: ({ row: { leftBoard, rightBoard } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            display="block"
            justifyContent="left"
            borderRadius="4px"
          >
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  leftBoard.builder1?.user?.id === id && colors.greenAccent[500]
                }
                color={leftBoard.builder1?.user?.id === id && colors.grey[900]}
              >
                {leftBoard.builder1?.user?.username}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  leftBoard.builder2?.user?.id === id && colors.greenAccent[500]
                }
                color={leftBoard.builder2?.user?.id === id && colors.grey[900]}
              >
                {leftBoard.builder2?.user?.username}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  rightBoard.builder1?.user?.id === id &&
                  colors.greenAccent[500]
                }
                color={rightBoard.builder1?.user?.id === id && colors.grey[900]}
              >
                {rightBoard.builder1?.user?.username}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  rightBoard.builder2?.user?.id === id &&
                  colors.greenAccent[500]
                }
                color={rightBoard.builder2?.user?.id === id && colors.grey[900]}
              >
                {rightBoard.builder2?.user?.username}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "gifter",
      headerName: "Gifter",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      cellClassName: "name-column--cell",
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.leftBoard.gifter1?.user?.username || ""} ${
          params.row.leftBoard.gifter2?.user?.username || ""
        } ${params.row.leftBoard.gifter3?.user?.username || ""} ${
          params.row.leftBoard.gifter4?.user?.username || ""
        } ${params.row.rightBoard.gifter1?.user?.username || ""} ${
          params.row.rightBoard.gifter2?.user?.username || ""
        } ${params.row.rightBoard.gifter3?.user?.username || ""} ${
          params.row.rightBoard.gifter4?.user?.username || ""
        }`,
      renderCell: ({ row: { leftBoard, rightBoard } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            display="block"
            justifyContent="left"
            borderRadius="4px"
          >
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="repeat(8, minmax(0, 1fr))"
            >
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  leftBoard.gifter1?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  leftBoard.gifter1 && !leftBoard.gifter1.confirmed
                    ? colors.redAccent[400]
                    : leftBoard.gifter1?.user?.id === id && colors.grey[900]
                }
              >
                {leftBoard.gifter1?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  leftBoard.gifter2?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  leftBoard.gifter2 && !leftBoard.gifter2.confirmed
                    ? colors.redAccent[400]
                    : leftBoard.gifter2?.user?.id === id && colors.grey[900]
                }
              >
                {leftBoard.gifter2?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  leftBoard.gifter3?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  leftBoard.gifter3 && !leftBoard.gifter3.confirmed
                    ? colors.redAccent[400]
                    : leftBoard.gifter3?.user?.id === id && colors.grey[900]
                }
              >
                {leftBoard.gifter3?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  leftBoard.gifter4?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  leftBoard.gifter4 && !leftBoard.gifter4.confirmed
                    ? colors.redAccent[400]
                    : leftBoard.gifter4?.user?.id === id && colors.grey[900]
                }
              >
                {leftBoard.gifter4?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  rightBoard.gifter1?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  rightBoard.gifter1 && !rightBoard.gifter1.confirmed
                    ? colors.redAccent[400]
                    : rightBoard.gifter1?.user?.id === id && colors.grey[900]
                }
              >
                {rightBoard.gifter1?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  rightBoard.gifter2?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  rightBoard.gifter2 && !rightBoard.gifter2.confirmed
                    ? colors.redAccent[400]
                    : rightBoard.gifter2?.user?.id === id && colors.grey[900]
                }
              >
                {rightBoard.gifter2?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  rightBoard.gifter3?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  rightBoard.gifter3 && !rightBoard.gifter3.confirmed
                    ? colors.redAccent[400]
                    : rightBoard.gifter3?.user?.id === id && colors.grey[900]
                }
              >
                {rightBoard.gifter3?.user?.username ?? "-"}
              </Typography>
              <Typography
                sx={{ gridColumn: "span 2" }}
                backgroundColor={
                  rightBoard.gifter4?.user?.id === id && colors.greenAccent[500]
                }
                color={
                  rightBoard.gifter4 && !rightBoard.gifter4.confirmed
                    ? colors.redAccent[400]
                    : rightBoard.gifter4?.user?.id === id && colors.grey[900]
                }
              >
                {rightBoard.gifter4?.user?.username ?? "-"}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "activeTime",
      headerName: "Durata",
      type: "number",
      sortable: false,
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.activeTime / 60,
      renderCell: ({ row: { activeTime } }) => secondsToDhms(activeTime),
    },
    {
      field: "closed",
      headerName: "Chiuso",
      type: "boolean",
      flex: 0.1,
      renderCell: (params) => {
        return params.value ? (
          <CheckIcon
            style={{
              color: theme.palette.success.light,
            }}
          />
        ) : (
          <CloseIcon
            style={{
              color: colors.grey[500],
            }}
          />
        );
      },
    },
  ];

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay /* + sDisplay*/;
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    window.open(`/boards/${params?.row?.id}`, "_blank").focus();
    // navigate(`/boards/${params?.row?.id}`);
  };

  const sendConfirmationEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await requestConfirmationEmail({ email: userData.email });
    await handleRequest(response, openSnackbar);
    setLoading(false);
  };

  const sendForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await forgotPassword({ email: userData.email });
    await handleRequest(response, openSnackbar);
    setLoading(false);
  };

  const restore = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await restoreAccount({
      id,
      admin: true,
    });
    setLoading(false);
    navigate("/user");
  };

  const EmailConfirmation = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await confirmEmail(userData.id);
    await handleRequest(response, openSnackbar);
    if (response.return) {
      setUserData(response.output);
    }
    setLoading(false);
  };

  return (
    <Box m="20px">
      <Header
        title={id ? "EDIT USER" : "CREATE USER"}
        subtitle={id ? "Edit a User Profile" : "Create a New User Profile"}
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: 1,
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            ".css-1grhalw-MuiButtonBase-root-MuiTab-root.Mui-selected": {
              color: "#00BCD4",
            },
          }}
        >
          <Tab label="Dati profilo" {...a11yProps(0)} />
          <Tab label="Storico isole" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {tab === 0 && (
        <Box>
          <Box
            display="flex"
            justifyContent="end"
            sx={{
              borderColor: "divider",
              marginBottom: 1,
            }}
          >
            {!userData.email_verified_at && (
              <Button
                type="button"
                color="info"
                variant="contained"
                sx={{
                  marginRight: 1,
                }}
                onClick={sendConfirmationEmail}
              >
                Invia email conferma profilo
              </Button>
            )}
            {!userData.email_verified_at && (
              <Button
                type="button"
                color="info"
                variant="contained"
                sx={{
                  marginRight: 1,
                }}
                onClick={EmailConfirmation}
              >
                Conferma account
              </Button>
            )}
            {userData.request_delete && (
              <Button
                type="button"
                color="info"
                variant="contained"
                sx={{
                  marginRight: 1,
                }}
                onClick={restore}
              >
                Annulla richiesta eliminazione
              </Button>
            )}
            <Button
              color="info"
              variant="contained"
              onClick={sendForgotPassword}
            >
              Invia email reset password
            </Button>
          </Box>
          <Formik
            onSubmit={handleFormSubmit}
            initialProps={{ ...userData }}
            initialValues={{ ...userData }}
            enableReinitialize={true}
            validationSchema={checkoutSchema}
            loading={loading}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstname}
                    name="firstname"
                    error={!!touched.firstname && !!errors.firstname}
                    helperText={touched.firstname && errors.firstname}
                    sx={{ gridColumn: "span 3" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastname}
                    name="lastname"
                    error={!!touched.lastname && !!errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                    sx={{ gridColumn: "span 3" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Contact Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone_number}
                    name="phone_number"
                    error={!!touched.phone_number && !!errors.phone_number}
                    helperText={touched.phone_number && errors.phone_number}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <FormControl sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="demo-multiple-name-label">
                      Access Level
                    </InputLabel>
                    <Select
                      fullWidth
                      variant="filled"
                      label="Access Level"
                      value={values.role}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.role && !!errors.role}
                      name="role"
                    >
                      <MenuItem value={"admin"}>Admin</MenuItem>
                      <MenuItem value={"eternal"}>Eternal</MenuItem>
                      <MenuItem value={"founder"}>Founder</MenuItem>
                      <MenuItem value={"assistente"}>Assistente</MenuItem>
                      <MenuItem value={"gestionale"}>Gestionale</MenuItem>
                      <MenuItem value={"user"}>User</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="demo-multiple-name-label">
                      Invitante
                    </InputLabel>
                    <Select
                      fullWidth
                      variant="filled"
                      label="Invitante"
                      value={values.sponsor_id ?? ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.sponsor_id && !!errors.sponsor_id}
                      name="sponsor_id"
                    >
                      <MenuItem value={undefined}>Seleziona Invitante</MenuItem>
                      {users.map((u) => (
                        <MenuItem key={u.id} value={u.id}>
                          {u.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="demo-multiple-name-label">Guida</InputLabel>
                    <Select
                      fullWidth
                      label="Guida"
                      variant="filled"
                      value={values.parent_id ?? ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.parent_id && !!errors.parent_id}
                      name="parent_id"
                    >
                      <MenuItem value={undefined}>Seleziona Guida</MenuItem>
                      {users.map((u) => (
                        <MenuItem key={u.id} value={u.id}>
                          {u.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    sx={{ gridColumn: "span 3" }}
                    label="I agree to the terms and conditions"
                    control={
                      <Checkbox
                        disabled
                        color="success"
                        name="terms_conditions_accepted"
                        checked={values.terms_conditions_accepted ?? 0}
                        onChange={handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{ gridColumn: "span 3" }}
                    label="Customer Support (Required)"
                    control={
                      <Checkbox
                        color="success"
                        name="email_customer_support"
                        checked={values.email_customer_support ?? 0}
                        onChange={handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{ gridColumn: "span 3" }}
                    label="Board Activity (Optional)"
                    control={
                      <Checkbox
                        color="success"
                        name="email_board_activity"
                        checked={values.email_board_activity ?? 0}
                        onChange={handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{ gridColumn: "span 3" }}
                    label="Marketing (Optional)"
                    control={
                      <Checkbox
                        color="success"
                        name="email_marketing"
                        checked={values.email_marketing ?? 0}
                        onChange={handleChange}
                      />
                    }
                  />
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    variant="filled"
                    type="text"
                    label="Note"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.note ?? ""}
                    name="note"
                    error={!!touched.note && !!errors.note}
                    helperText={touched.note && errors.note}
                    sx={{ gridColumn: "span 6" }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="button"
                    color="info"
                    variant="contained"
                    onClick={() => navigate("/user")}
                  >
                    Torna all' elenco
                  </Button>
                  <Button type="submit" color="secondary" variant="contained">
                    {id ? "Edit User" : "Create New User"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
      {tab === 1 && (
        <Box
          m="25px 0 0 0"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            // autoHeight
            rows={pageState.data}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            pagination
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({ ...old, pageSize: newPageSize }))
            }
            columns={columns}
            onRowClick={handleRowClick}
            onSortModelChange={setSortModel}
            onFilterModelChange={setFilterModel}
            // slots={{ toolbar: GridToolbar }}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstname: yup.string().required("required"),
  lastname: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phone_number: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  username: yup.string().required("required"),
  role: yup
    .mixed()
    .oneOf(["admin", "eternal", "founder", "assistente", "gestionale", "user"])
    .defined()
    .required("required"),
  sponsor_id: yup.number().nullable(),
});
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone_number: "",
  password: "",
  username: "",
  role: "",
  email_verified_at: "",
  board_type: "",
  parent_id: "",
  sponsor_id: "",
  manager_id: "",
  level_id: "",
  terms_conditions_accepted: false,
  email_customer_support: false,
  email_board_activity: false,
  email_marketing: false,
  note: "",
};

export default UserProfile;
