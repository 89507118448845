import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Modal,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

const UserBox = (props) => {
  const {
    title,
    board,
    legend,
    onAddGifter,
    onConfirmGifter,
    onRemoveGifter,
    users,
  } = props;
  const [addNewGifter, setAddNewGifter] = useState();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BoxStyle = {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "25%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    overflow: "hidden",
    padding: "5px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const addGifter = () => {
    onAddGifter(addNewGifter);
    handleClose();
  };

  return (
    <Box sx={BoxStyle}>
      {title}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "50%",
          //   maxHeight: "60%",
        }}
      >
        {board?.user || legend ? (
          <Box
            sx={{
              width: "100%",
              backgroundColor:
                board?.user && board.confirmed == 0
                  ? "orange"
                  : board?.user && board.confirmed == 2
                  ? "red"
                  : "green",
            }}
          >
            <Typography variant="h4" component="h5">
              {board?.user?.username ?? legend?.username}
            </Typography>
            <Typography>
              {`${board?.user?.firstname ?? legend?.firstname} ${
                board?.user?.lastname ?? legend?.lastname
              }`}
            </Typography>
            <Typography>
              {board?.user?.phone_number ?? legend?.phone_number}
            </Typography>
          </Box>
        ) : (
          onAddGifter && (
            <Autocomplete
              sx={{ mt: 2, width: "100%" }}
              disablePortal
              onChange={(event: any, newValue: string | null) => {
                setAddNewGifter(newValue);
              }}
              id="combo-box-demo"
              options={users.map((u) => ({ ...u, label: u.username }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} label="Seleziona Gifter" />
              )}
            />
          )
        )}
      </Box>
      {(board?.user || legend) && (
        <Button
          sx={{ padding: "6px 4px", minWidth: "40px" }}
          onClick={() => navigate(`/user/${board?.user?.id}`)}
        >
          <VisibilityIcon title="Visualizza profilo utente" color="action" />
        </Button>
      )}
      {board?.user && (
        <span>
          {(board.confirmed == 0 || board.confirmed == 2) && (
            <Button
              onClick={onConfirmGifter}
              sx={{ padding: "6px 4px", minWidth: "40px" }}
            >
              <CheckIcon title="Conferma gifter" color="success" />
            </Button>
          )}
          {board.position == 3 && (
            <Button
              onClick={onRemoveGifter}
              sx={{ padding: "6px 4px", minWidth: "40px" }}
            >
              <DeleteIcon title="Remove gifter" color="error" />
            </Button>
          )}
        </span>
      )}
      {!board?.user && !board && onAddGifter && (
        <Button
          onClick={handleOpen}
          sx={{ padding: "6px 4px", minWidth: "40px" }}
        >
          <AddCircleIcon title="Add gifter" color="success" />
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Aggiungi Gifter
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2, mb: 2 }}>
            {addNewGifter?.id ? (
              <span>
                Aggiungere <strong>{addNewGifter.username}</strong> alla tavola
                ?
              </span>
            ) : (
              <span> Selezionare prima il Gifter </span>
            )}
          </Typography>
          <Button onClick={handleClose} variant="secondary">
            Annulla
          </Button>
          <Button onClick={addGifter} variant="info">
            Conferma
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserBox;
