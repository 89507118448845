import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { DataGrid, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../App";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import {
  getBoardLevel,
  updateBoardLevel,
  handleRequest,
} from "../../../services/ApiClass";
import { useSnackbar } from "../../../components/SnackbarContext"; // Import useSnackbar

const IsolaDetail = (props) => {
  let { id } = useParams();
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [levelData, setLevelData] = useState(initialValues);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (!state.tokenJwt) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getBoardLevel(id).then((response) => {
      handleRequest(response, openSnackbar);
      setLevelData(response.output);
      setLoading(false);
    });
  }, [id]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const response = await updateBoardLevel(id, values);
    handleRequest(response, openSnackbar);
    setLoading(false);
  };

  return (
    <Box m="20px">
      <Header
        title={id ? "EDIT USER" : "CREATE USER"}
        subtitle={id ? "Edit a User Profile" : "Create a New User Profile"}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Formik
        onSubmit={handleFormSubmit}
        initialProps={{ ...levelData }}
        initialValues={{ ...levelData }}
        enableReinitialize={true}
        validationSchema={checkoutSchema}
        loading={loading}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(6, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 3" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Value"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.value}
                name="value"
                error={!!touched.value && !!errors.value}
                helperText={touched.value && errors.value}
                sx={{ gridColumn: "span 3" }}
              />
              <TextField
                fullWidth
                multiline
                minRows={3}
                variant="filled"
                type="text"
                label="Note"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.note ?? ""}
                name="note"
                error={!!touched.note && !!errors.note}
                helperText={touched.note && errors.note}
                sx={{ gridColumn: "span 6" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="button"
                color="info"
                variant="contained"
                onClick={() => navigate("/isola")}
              >
                Torna all' elenco
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  value: yup.number().nullable(),
});
const initialValues = {
  name: "",
  value: 0,
  note: "",
};

export default IsolaDetail;
