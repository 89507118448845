import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { AuthContext } from "../../App";
import { boardLevel } from "../../services/ApiClass";

const Isola = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isole, setIsole] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (!state.tokenJwt) {
      navigate("/login");
    }
    setLoading(true);
    boardLevel().then((response) => {
      setIsole(response.output);
      setLoading(false);
    });
  }, []);

  const columns = [
    { field: "id", headerName: "ID", type: "number" },
    {
      field: "value",
      headerName: "Value",
      type: "number",
    },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: "Descrizione",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];

  // const handleOnCellClick = (params) => {
  //   console.log(params);
  // };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    navigate(`/isola/${params?.row?.id}`);
  };

  return (
    <Box m="20px">
      <Header title="Users" subtitle="Managing Users" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          rows={isole}
          columns={columns}
          loading={loading}
          components={{ Toolbar: GridToolbar }}
          onRowClick={handleRowClick}
          // onCellClick={handleOnCellClick}
        />
      </Box>
    </Box>
  );
};

export default Isola;
